import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import QueryString from "query-string"
import useCertification from "../../hooks/useCertification"
import PageRoot from "../../components/PageRoot"
import { Skeleton } from "antd"
import ErrorScreen from "../../components/ErrorScreen"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { devLog } from "../../utils"
import ExamReview from "../../components/ExamReview"

const StartExamPage = ({ location }) => {
  const { t } = useTranslation()
  const { getUserExamResult } = useCertification()

  const params = QueryString.parse(location.search)
  const { id } = params
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [examData, setExamData] = useState(null)

  const loadExam = async () => {
    setLoading(true)
    try {
      const data = await getUserExamResult(id)
      devLog({ examData: data })
      setExamData(data)
    } catch (e) {
      setError(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadExam()
  }, [id])

  const content = useMemo(() => {
    if (loading) {
      return (
        <ContainerWithPadding size="small">
          <Skeleton active round />
        </ContainerWithPadding>
      )
    }

    if (error) {
      return (
        <ContainerWithPadding size="small">
          <ErrorScreen error={error} learnMoreUrl={`/go?id=${id}`} />
        </ContainerWithPadding>
      )
    }

    return (
      <ContainerWithPadding size="small">
        <div className="review-questions">
          <ExamReview
            result={examData.examResult}
            quizzes={examData.quizzes}
            userAnswers={examData.userAnswers}
          />
        </div>
      </ContainerWithPadding>
    )
  }, [error, examData, loading])

  return <PageRoot title={t("label:startExam")}>{content}</PageRoot>
}

export default StartExamPage
