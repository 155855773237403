import { useAuth0 } from "@auth0/auth0-react"
import fetch from "cross-fetch"
import { devLog } from "../utils"

const useCertification = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()

  const startExam = async certificationId => {
    const url = `/.netlify/functions/start-exam`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ certificationId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { data, error } = await response.json()

    if (error) {
      devLog({ error })

      throw new Error(error.code || error.message)
    }

    if (response.status !== 200) {
      throw new Error("500")
    }

    return data
  }

  const verifyExam = async ({ certificationId, examId, answers, quizIds }) => {
    const url = `/.netlify/functions/verify-exam`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ certificationId, examId, answers, quizIds }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { data, error } = await response.json()

    if (error) {
      throw new Error(error.code)
    }

    if (response.status !== 200) {
      throw new Error("500")
    }

    return data
  }

  const requireShipping = async ({ certificationId, shippingAddress }) => {
    const url = `/.netlify/functions/ship-certification`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ certificationId, shippingAddress }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { data, error } = await response.json()

    if (error) {
      devLog({ error })
      throw new Error(error.code)
    }

    if (response.status !== 200) {
      throw new Error("500")
    }

    return data
  }

  const getUserExamResult = async certificationId => {
    const url = `/.netlify/functions/get-exam-result`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ certificationId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { data, error } = await response.json()

    if (error) {
      devLog({ error })
      throw new Error(error.code)
    }

    if (response.status !== 200) {
      throw new Error("500")
    }

    return data
  }

  return {
    startExam,
    verifyExam,
    requireShipping,
    getUserExamResult,
  }
}

export default useCertification
